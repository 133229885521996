import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Personalizza from "../../components/personalizza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

// markup
const BibsPage = () => {
  return (
    <Page
      lang="en"
      title="Non-woven disposable bibs"
      description="Our non-woven disposable bibs are a fun and convenient additional service, highly appreciated and quick to use for dishes where there is a chance of getting dirty."
      type="CollectionPage"
      readMore={["/richiedi-campione-gratuito"]}
      image="/bavagli-in-tnt.jpg"
    >
      <Hero
        title="Non-woven disposable bibs"
        description="A fun and convenient solution for your customers, from the largest to the smallest."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h2">
                Sizes, colors, and graphics,
                <br />
                choose the right non-woven disposable bib for you.
              </Typography>
              <Typography>
                We provide the product for both adults and children, to be
                fastened or worn directly around the neck.
              </Typography>
              <Typography>
                Our non-woven disposable bibs are a fun and convenient
                additional service, highly appreciated and quick to use for
                dishes where there is a chance of getting dirty.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-nero-aragosta.jpg"
                alt="Black lobster disposable non-woven bib"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Black “lobster” maxi disposable non-woven bib
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/en/request-free-sample"
                title="Find out more about free samples"
              >
                <Button variant="outlined" color="info" size="small">
                  Free sample
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-papillon.jpg"
                alt="White papillon disposable non-woven bib"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              White “papillon” disposable non-woven bib
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/en/request-free-sample"
                title="Find out more about free samples"
              >
                <Button variant="outlined" color="info" size="small">
                  Free sample
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-aragosta.jpg"
                alt="White lobster disposable non-woven bib"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              White “lobster” disposable non-woven bib
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/en/request-free-sample"
                title="Find out more about free samples"
              >
                <Button variant="outlined" color="info" size="small">
                  Free sample
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-bambino.jpg"
                alt="Baby disposable non-woven bib"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Baby disposable non-woven bib
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/en/request-free-sample"
                title="Find out more about free samples"
              >
                <Button variant="outlined" color="info" size="small">
                  Free sample
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-personalizzato.jpg"
                alt="Customized disposable non-woven bib"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Customized disposable non-woven bib
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/en/request-free-sample"
                title="Find out more about free samples"
              >
                <Button variant="outlined" color="info" size="small">
                  Free sample
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Section>

      <Personalizza language="en" />
    </Page>
  );
};

export default BibsPage;
